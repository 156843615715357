import React, { useState, useContext, useEffect, useCallback } from 'react';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

import { StoreContext } from 'context/StoreContext';
import { getPrice } from 'utils/store';
import {
  GetStoreProductDetailsQuery,
  ShopifyProductVariant,
  ShopifyProductVariantSelectedOptions,
  ShopifyProductVariantPriceV2,
} from 'types';

import styles from './ProductDetailsForm.module.css';

interface IProductDetailsFormProps {
  product: GetStoreProductDetailsQuery['shopifyProduct'];
}

type ProductDetailsFormVariant = Pick<
  ShopifyProductVariant,
  'id' | 'title' | 'shopifyId' | 'availableForSale'
> & {
  priceV2?: Pick<ShopifyProductVariantPriceV2, 'amount' | 'currencyCode'>;
  selectedOptions?: Pick<
    ShopifyProductVariantSelectedOptions,
    'name' | 'value'
  >[];
};

export const ProductDetailsForm: React.FC<IProductDetailsFormProps> = ({
  product,
}) => {
  const {
    options,
    variants,
    variants: [initialVariant],
  } = product;
  const [variant, setVariant] = useState<ProductDetailsFormVariant>({
    ...initialVariant,
  });
  const [quantity, setQuantity] = useState<string>('1');
  const {
    addVariantToCart,
    store: { client, adding },
  } = useContext(StoreContext);

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant;

  const [available, setAvailable] = useState(productVariant.availableForSale);

  const checkAvailability = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        // this checks the currently selected variant for availability
        const result = fetchedProduct.variants.filter(
          variant => variant.id === productVariant.shopifyId
        );
        if (result.length > 0) {
          setAvailable(result[0].available);
        }
      });
    },
    [client.product, productVariant.shopifyId]
  );

  // Check availability upon product variant change
  useEffect(() => {
    checkAvailability(product.shopifyId);
  }, [productVariant, checkAvailability, product.shopifyId]);

  /*
   * HANDLERS
   * ==========================================================
   */
  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    setQuantity(target.value);
  };

  const handleOptionChange = (
    optionIndex: number,
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const {
      target: { value },
    } = e;
    const currentOptions = [...variant.selectedOptions];

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    };

    const selectedVariant = find(variants, ({ selectedOptions }) =>
      isEqual(currentOptions, selectedOptions)
    );

    setVariant({ ...selectedVariant });
  };

  const handleAddToCart = () => {
    addVariantToCart(productVariant.shopifyId, quantity);
  };
  /* ==========================================================
   * END HANDLERS
   */

  /*
  Using this in conjunction with a select input for variants
  can cause a bug where the buy button is disabled, this
  happens when only one variant is available and it's not the
  first one in the dropdown list. I didn't feel like putting
  in time to fix this since its an edge case and most people
  wouldn't want to use dropdown styled selector anyways -
  at least if the have a sense for good design lol.
  */
  const checkDisabled = (name: string, value: string) => {
    const match = find(variants, {
      selectedOptions: [
        {
          name: name,
          value: value,
        },
      ],
    });
    if (match === undefined) return true;
    if (match.availableForSale === true) return false;
    return true;
  };

  const price = getPrice({
    amount: variant.priceV2.amount,
    currencyCode: variant.priceV2.currencyCode,
  });

  return (
    <div className={styles.productFormWrap}>
      <h2 className={styles.price}>{price}</h2>
      {options.map(({ id, name, values }, index) => {
        // Remove Printful non-useful values
        if (name === 'Title' && values[0] === 'Default Title') {
          return null;
        }

        return (
          <div className={styles.option} key={id}>
            <label htmlFor={name} className={styles.label}>
              {name}{' '}
            </label>
            <select
              name={name}
              key={id}
              onChange={event => handleOptionChange(index, event)}
              onBlur={event => handleOptionChange(index, event)}
              className={styles.input}
            >
              {values.map(value => (
                <option
                  value={value}
                  key={`${name}-${value}`}
                  disabled={checkDisabled(name, value)}
                >
                  {value}
                </option>
              ))}
            </select>
          </div>
        );
      })}
      <div className={styles.option}>
        <label htmlFor="quantity" className={styles.label}>
          Quantity{' '}
        </label>
        <input
          type="number"
          id="quantity"
          name="quantity"
          min="1"
          step="1"
          onChange={handleQuantityChange}
          value={quantity}
          className={styles.input}
        />
      </div>
      <button
        type="submit"
        disabled={!available || adding}
        onClick={handleAddToCart}
        className={styles.btn}
      >
        Add to Cart
      </button>
      {!available && <p>This Product is out of Stock!</p>}
    </div>
  );
};
