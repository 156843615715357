import React from 'react';
import Img from 'gatsby-image';

import Loading from 'components/common/Loading';
import { isBrowser } from 'utils/environment';
import { ProductDetailsForm } from './ProductDetailsForm';

import styles from './ProductDetails.module.css';
import { GetStoreProductDetailsQuery } from 'types';

// tiny-slider breaks server-side rendering, until that is fixed
// we should only require tiny-slider when out of SSR
// https://github.com/jechav/tiny-slider-react/issues/25
const Slider = React.lazy(() => import('components/common/Slider'));

interface IProductDetailsProps {
  product: GetStoreProductDetailsQuery['shopifyProduct'];
}

export const ProductDetails: React.FC<IProductDetailsProps> = ({ product }) => {
  const images = product.images.map(image => (
    <Img
      fluid={image.localFile.childImageSharp.fluid}
      key={image.id}
      alt={product.title}
    />
  ));

  return (
    <div className={styles.page}>
      <header className={styles.header}>
        <div className={styles.grid}>
          <div className={styles.gridLeft}>
            {isBrowser && (
              <React.Suspense fallback={<Loading />}>
                <Slider>{images}</Slider>
              </React.Suspense>
            )}
          </div>
          <div className={styles.gridRight}>
            <h1 className={styles.productTitle}>{product.title}</h1>
            <ProductDetailsForm product={product} />
          </div>
        </div>
      </header>
      <section className={styles.productDescriptionSection}>
        <div>
          <p
            className={styles.productDescription}
            dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
          />
        </div>
      </section>
    </div>
  );
};
