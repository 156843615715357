import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/layout';
import { ProductDetails } from 'components/Store/ProductDetails';
import { STORE_PRODUCT_DETAILS_SEO } from 'utils/seo';

import { GetStoreProductDetailsQuery } from 'types';

interface IStoreProductDetailsProps {
  data: GetStoreProductDetailsQuery;
  location: Location;
}

const StoreProductTemplate: React.FC<IStoreProductDetailsProps> = ({
  data,
}) => {
  const product = data.shopifyProduct;
  const { title, description } = product;

  return (
    <Layout
      title={STORE_PRODUCT_DETAILS_SEO.title(title)}
      description={STORE_PRODUCT_DETAILS_SEO.description(description)}
      image={STORE_PRODUCT_DETAILS_SEO.image(product.images[0]?.originalSrc)}
    >
      <ProductDetails product={product} />
    </Layout>
  );
};

export const storeProductDetailsQuery = graphql`
  query GetStoreProductDetails($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        priceV2 {
          amount
          currencyCode
        }
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;

export default StoreProductTemplate;
